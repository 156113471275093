<template>
	<div class="skeleton">
		<div v-for="item in 2" :key="item" class="skeleton-item">
			<div class="row-1">
				<div class="l1" />
				<div class="l2" />
			</div>
			<div class="row-2">
				<div class="row-2-l" />
				<div class="row-2-r">
					<div class="l1" />
					<div class="l1" />
					<div class="l1" />
					<div class="l1" />
					<div class="l1" />
					<div class="l2">
						<div></div>
						<div></div>
						<div></div>
					</div>
					<div class="l3"><div></div></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: '',
	components: {},
	data() {
		return {};
	},
	created() {},
	mounted() {},
	methods: {}
};
</script>

<style lang="scss" scoped>
@keyframes skeleton-blink {
	50% {
		opacity: 0.6;
	}
}
.skeleton {
	.skeleton-item {
		background: #fff;
		box-shadow: 0px 4px 24px 0px rgba(100, 101, 102, 0.12);
		padding: 8px 12px;
		border-radius: 8px;
		animation: skeleton-blink 1.2s ease-in-out infinite;
		&:not(:first-child) {
			margin-top: 10px;
		}
		.row-1 {
			display: flex;
			justify-content: space-between;
			.l1 {
				width: calc(100% - 100px);
				height: 21px;
				background: #e0e0e0;
			}
			.l2 {
				width: 95px;
				height: 21px;
				background: #e0e0e0;
			}
		}
		.row-2 {
			display: flex;
			align-items: flex-start;
			margin-top: 8px;
			.row-2-l {
				width: 88px;
				height: 88px;
				border-radius: 4px;
				background: #f2f3f5;
			}
			.row-2-r {
				margin-left: 10px;
				width: calc(100% - 98px);
				line-height: 14px;
				.l1 {
					height: 12px;
					width: 80%;
					background: #f2f3f5;
					&:not(:last-child) {
						margin-bottom: 4px;
					}
					&:nth-child(1),
					&:nth-child(2) {
						width: 50%;
					}
				}
				.l2 {
					display: flex;
					div {
						line-height: 14px;
						height: 14px;
						margin-right: 10px;
						width: calc(33% - 10px);
						background: #f2f3f5;
					}
				}
				.l3 {
					display: flex;
					justify-content: flex-end;
					margin-top: 8px;
					div {
						border-radius: 32px;
						border: 1px solid #f2f3f5;
						height: 32px;
						line-height: 30px;
						width: 102px;
						text-align: center;
						font-size: 14px;
						color: #f2f3f5;
						background: #f2f3f5;
					}
				}
			}
		}
	}
}
</style>
