<template>
	<div class="home">
		<div class="main-header">
			<div class="search-wrap">
				<searchInput v-model="baseData.query" @search="searchEvent"></searchInput>
			</div>
			<button type="button" class="filter-btn" @click="filterEvent">
				筛选
				<van-icon name="arrow-down" />
			</button>
		</div>
		<div class="main-container">
			<van-pull-refresh class="main-content" v-model="isRefresh" @refresh="onRefresh">

				<van-list class="data-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">

					<div class="data-item" v-for="(item, index) in deviceList" :key="item.deviceId">
						<div class="title-wrap">
							<p class="title">{{ item.deviceName || '-' }}</p>
							<van-rate color="#ffd21e" void-icon="star" void-color="#eee" readonly :size="14"
								class="rate" v-model="item.levelNum" />
							<div style="clear: both"></div>
						</div>
						<div class="data-wrap">
							<div class="img-wrap"><img class="img" :src="image(item)" alt="" /></div>
							<div class="right-info">
								<div class="info-item">
									<span class="label">品牌</span>
									<span class="value">{{ item.brandName || '-' }}</span>
								</div>
								<div class="info-item">
									<span class="label">型号</span>
									<span class="value">{{ item.modelName || '-' }}</span>
								</div>
								<div class="info-item">
									<span class="label">{{$VUEAPPDEVICECODE()}}</span>
									<span class="value">{{ item.code || '-' }}</span>
								</div>
								<div class="info-item">
									<span class="label">设备分类</span>
									<span class="value">{{ item.categoryName || '-' }}</span>
								</div>
								<div class="info-item">
									<span class="label">设备科室</span>
									<span class="value">{{ item.tenantName || '-' }}</span>
								</div>
								<div class="info-item">
									<span class="label">卡片号</span>
									<span class="value">{{ item.label || '-' }}</span>
								</div>
								<!--                <div class="info-item">-->
								<!--                  <span class="label">计费方式</span>-->
								<!--                  <span class="value">{{ item.label || '-' }}</span>-->
								<!--                </div>-->
								<!-- <div class="info-item">
              <span class="label">卡片号</span>
              <span class="value">{{item.label||'-'}}</span>
            </div> -->
								<div class="tag-wrap">
									<div class="tag-item">机时利用率 {{ item.coefficient || 0 }}%</div>
									<div class="tag-item">开机次数 {{ item.powerOnTotals || 0 }}</div>
									<div class="tag-item">设备评分 {{ item.level }}</div>
								</div>
							</div>
						</div>
						<div class="footer-handle">
							<button type="button" @click="shareEvent(item)">共享</button>
						</div>
					</div>

				</van-list>

			</van-pull-refresh>

		</div>

		<filterPopup ref="filterPopup" height="80%" @onReset="onReset" @onConfirm="onConfirm">
			<div class="filter-container">
				<filterPopupItem label="推荐分类">
					<div class="list">
						<div class="list-item" v-for="(item, index) in baseOptions.categoryList" :key="index"
							:class="{ active: baseData.categoryIds.includes(item.id) }" @click="categoryEvent(item)">
							{{ item.name }}
						</div>
					</div>
				</filterPopupItem>
				<filterPopupItem label="推荐品牌">
					<div class="list">
						<div class="list-item" v-for="(item, index) in baseOptions.brandList" :key="index"
							:class="{ active: baseData.brandIds.includes(item.id) }" @click="brandEvent(item)">
							{{ item.name }}
						</div>
					</div>
				</filterPopupItem>
			</div>
		</filterPopup>

		<filterPopup ref="sharePopup" title="共享设置" height="400px" resetText="取消" @onReset="shareReset"
			@onConfirm="shareConfirm">
			<div class="filter-container share-filter-container">
				<filterPopupItem label="计算类型">
					<div class="list">
						<div v-for="(item,index) in billingTypeOptions" :key="index" class="list-item"
							:class="{ active: shareConfig.billingType ==item.value }" @click="billingTypeChange(item)">
							{{item.label}}
						</div>

					</div>
				</filterPopupItem>
				<filterPopupItem label="价格">
					<van-field class="price-input" v-model="shareConfig.price" type="number" placeholder="请输入价格"
						required :error-message="priceInputErrorMsg"
						:readonly="shareConfig.billingType==2||shareConfig.billingType==4" @input="priceInputEvent">
						<template #extra>
							元/{{ billingTypeUnit }}
						</template>
					</van-field>
				</filterPopupItem>
				<filterPopupItem label="用后消毒">
					<div class="list">
						<div class="list-item" :class="{ active: shareConfig.cleanType === 0 }"
							@click="shareConfig.cleanType = 0">
							需要
						</div>
						<div class="list-item" :class="{ active: shareConfig.cleanType === 1 }"
							@click="shareConfig.cleanType = 1">
							不需要
						</div>
					</div>
				</filterPopupItem>
			</div>
		</filterPopup>
	</div>
</template>

<script>
	import skeleton from './skeleton.vue'
	import billingTypeOptions from '@/baseConfig/billingTypeOptions/index.js'
	import {
		mapGetters
	} from 'vuex'

	export default {
		name: 'home',
		components: {
			skeleton
		},
		data() {
			return {
				isRefresh: false,
				page: 1,
				pageSize: 30,
				finished: false,
				baseData: {
					query: '',
					categoryIds: [],
					brandIds: []
				},
				baseOptions: {
					categoryList: [],
					brandList: []
				},
				deviceList: [],

				shareConfig: {
					deviceId: '',
					billingType: 0,
					price: '',
					cleanType: 0
				},
				priceInputErrorMsg: '',
				billingTypeOptions: [],

				loading: false
			}
		},
		computed: {
			...mapGetters(['prejectConfig']),
			billingTypeUnit() {
				const res = billingTypeOptions.filter(item => {
					return item.value == this.shareConfig.billingType
				})
				return res[0].unit
			}
		},
		created() {
			this.sharePoolConfig()
			this.getList(() => {}, false)

			// 根据配置设置计算选项
			if (this.prejectConfig && this.prejectConfig.globalConfig.ciantongShareDispatchBillingType) {
				const result = billingTypeOptions.filter(item => {
					return this.prejectConfig.globalConfig.ciantongShareDispatchBillingType.includes(item.value)
				})
				this.billingTypeOptions = result
			} else {
				this.billingTypeOptions = billingTypeOptions
			}
		},
		mounted() {},
		methods: {
			image(item) {
				if (this.$valueIsExist(item, 'avatar') && item['avatar'] != '') {
					let photo = item['avatar']
					return this.$replacePhoto(
						`${window.ipConfig.VUE_APP_BASEURL}/api/image/get?id=${this.$getPhotosId(photo)}&scale=0.1`)
				}
				return this.$replacePhoto('')
			},
			onLoad: function() {
				if (this.deviceList.length) {
					this.page = this.page + 1
					this.isRefresh = true
					this.getList(() => {
						setTimeout(() => {
							this.isRefresh = false
						}, 300)
					}, true)
				}
			},
			onRefresh() {
				this.isRefresh = true
				this.page = 1
				this.getList(() => {
					setTimeout(() => {
						this.isRefresh = false
					}, 300)
				})
			},

			filterEvent() {
				this.$refs.filterPopup.show()
			},
			searchEvent() {
				this.page = 1;
				this.getList(() => {}, false)
			},
			getList(callback, isOnLoad = false) {
				this.loading = true
				this.$api.deviceWorkManager
					.shareDeviceList({
						query: this.baseData.query,
						// tenantIds: '',
						isTenantIdChild: 1,
						categoryIds: this.baseData.categoryIds.join(),
						isCategoryIdChild: 1,
						brandIds: this.baseData.brandIds.join(),
						page: this.page,
						pageSize: this.pageSize
					})
					.then(res => {
						if (isOnLoad) {
							this.deviceList = this.deviceList.concat(res.data)
						} else {
							this.deviceList = res.data
						}
						this.loading = false
						this.finished = this.deviceList.length >= res.totalCount
						if (typeof callback == 'function') callback()
					})
					.catch(() => {
						this.loading = false
						if (typeof callback == 'function') callback()
					})
			},
			sharePoolConfig() {
				const userInfo = this.$store.getters.userInfo
				this.$api.deviceWorkManager
					.sharePoolConfig({
						tenantId: userInfo.tenantId
					})
					.then(res => {
						this.baseOptions.categoryList = res.data.categoryList
						this.baseOptions.brandList = res.data.brandList
					})
					.catch(() => {})
			},
			categoryEvent(item) {
				if (this.baseData.categoryIds.includes(item.id)) {
					this.baseData.categoryIds.splice(this.baseData.categoryIds.indexOf(item.id), 1)
				} else {
					this.baseData.categoryIds.push(item.id)
				}
			},
			brandEvent(item) {
				if (this.baseData.brandIds.includes(item.id)) {
					this.baseData.brandIds.splice(this.baseData.brandIds.indexOf(item.id), 1)
				} else {
					this.baseData.brandIds.push(item.id)
				}
			},
			onReset() {
				this.baseData.categoryIds = []
				this.baseData.brandIds = []
			},
			onConfirm() {
				this.$refs.filterPopup.hide()
				this.searchEvent()
			},

			shareEvent(item) {
				if (this.shareConfig.deviceId != item.deviceId) {
					this.shareConfig = {
						deviceId: item.deviceId,
						billingType: 0,
						price: '',
						cleanType: 0
					}
				}
				this.shareConfig.billingType = this.billingTypeOptions[0].value
				if (this.shareConfig.billingType == 2 || this.shareConfig.billingType == 4) {
					this.getDeviceDepreciation()
				}
				this.$refs.sharePopup.show()
			},
			billingTypeChange(item) {
				this.shareConfig.billingType = item.value
				this.shareConfig.price = ''
				if (this.shareConfig.billingType == 2 || this.shareConfig.billingType == 4) {
					this.getDeviceDepreciation()
				}
			},
			getDeviceDepreciation() {
				this.$api.deviceWorkManager
					.deviceZj({
						id: this.shareConfig.deviceId,
						type: this.shareConfig.billingType
					})
					.then(res => {
						this.shareConfig.price = res.data
					})
					.catch(e => {})
			},
			priceInputEvent() {
				if (!this.shareConfig.price) {
					this.priceInputErrorMsg = '请输入价格'
				} else {
					this.priceInputErrorMsg = ''
				}
			},
			shareReset() {
				this.$refs.sharePopup.hide()
			},
			shareConfirm() {
				if (!this.shareConfig.price) {
					this.priceInputErrorMsg = '请输入价格'
					return
				}
				this.$dialog
					.confirm({
						message: '确定共享此设备吗？'
					})
					.then(() => {
						this.$api.deviceWorkManager
							.deviceAddOrMoveShare({
								...this.shareConfig,
								share: 1
							})
							.then(res => {
								this.$toast.success('已加入共享池')
								this.searchEvent()
							})
							.catch(e => {
								this.$toast.fail('共享失败')
							})
							.finally(() => {
								this.$refs.sharePopup.hide()
							})
					})
					.catch(() => {})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '@styles/variables.scss';

	.home {
		height: 100%;
		background: linear-gradient(360deg, rgba(114, 171, 253, 0) 0%, #568efc 70%, #3e73fb 100%);
		background-size: 100% 195px;
		background-repeat: no-repeat;

		.main-header {
			margin-bottom: 10px;
			padding: 8px 16px 0 16px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.search-wrap {
				width: calc(100% - 50px);
			}

			.filter-btn {
				font-size: 12px;
				color: #fff;
				background: none;
				line-height: 32px;

				.van-icon {
					margin-left: 4px;
				}
			}
		}

		.main-container {
			height: calc(100% - 50px - 10px);

			.main-content {
				height: 100%;
				padding: 0 10px;
				overflow-y: auto;
			}

			.data-list {
				.data-item {
					background: linear-gradient(180deg, #d1deff 0%, #ffffff 100%);
					box-shadow: 0px 1px 1px 0px rgba(100, 101, 102, 0.1);
					padding: 8px 12px;
					border-radius: 8px;

					&:not(:first-child) {
						margin-top: 10px;
					}

					.title-wrap {
						.title {
							width: calc(100% - 100px);
							font-weight: 700;
							color: #333;
							float: left;
						}

						.rate {
							width: 95px;
							float: right;
						}
					}

					.data-wrap {
						margin-top: 8px;
						display: flex;
						align-items: flex-start;

						.img-wrap {
							width: 88px;
							height: 88px;
							border-radius: 4px;
							overflow: hidden;

							img {
								min-width: 60px;
								min-height: 60px;
								max-width: 88px;
								max-height: 88px;
							}
						}

						.right-info {
							margin-left: 10px;
							width: calc(100% - 88px - 10px);

							.info-item {
								font-size: 12px;
								color: #999;
								display: flex;
								line-height: 12px;
								margin-bottom: 4px;
								text-align: left;

								.label {
									width: 72px;
								}

								.value {
									width: calc(100% - 72px);
									color: #333;
								}
							}

							.tag-wrap {
								margin-top: 2px;
								width: 100%;
								display: flex;
								justify-content: flex-start;

								.tag-item {
									line-height: 14px;
									font-size: 10px;
									padding: 0 4px;
									background: rgba(62, 115, 251, 0.1);
									border-radius: 4px;
									color: #3e73fb;
									margin-right: 10px;

									&:last-child {
										background: rgba(255, 46, 46, 0.1);
										color: #ff2e2e;
									}
								}
							}
						}
					}

					.footer-handle {
						display: flex;
						justify-content: flex-end;
						margin-top: 8px;

						button {
							border-radius: 32px;
							border: 1px solid #3e73fb;
							height: 32px;
							line-height: 30px;
							width: 102px;
							text-align: center;
							font-size: 14px;
							color: #3e73fb;
							background: #fff;
						}
					}
				}
			}
		}
	}

	.filter-container {
		.list {
			display: flex;
			flex-wrap: wrap;

			.list-item {
				text-align: center;
				line-height: 30px;
				border-radius: 32px;
				background: #eee;
				border: 1px solid #eee;
				font-size: 14px;
				color: #333;
				transition: all 0.2s linear;
				padding: 0 12px;
				margin-bottom: 10px;
				max-width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				&:not(:last-child) {
					margin-right: 10px;
				}

				&.active {
					background: #d8e3ff;
					color: #3e73fb;
					border-color: #3e73fb;
				}
			}
		}
	}

	.share-filter-container {
		.list {
			.list-item {
				width: 30%;
				margin-right: 3%;
			}
		}

		.price-input {
			border-bottom: 1px solid #eee;
			padding: 4px 16px;
		}
	}

	::v-deep .main-container .van-pull-refresh {
		height: 100%;
		overflow: auto;

	}
</style>
